
import HomePage from '../pages/home.vue';
import AboutPage from '../pages/about.vue';
import CatalogPage from '../pages/catalog.vue';
import ProductPage from '../pages/product.vue';
import SettingsPage from '../pages/settings.vue';
import NotFoundPage from '../pages/404.vue';

import Menu_inventaire from '../pages/menu_inventaire.vue';
import Menu_synchro from '../pages/menu_synchro.vue';

import Cmde_liste from '../pages/cmde_liste.vue';
import Cmde_entete from '../pages/cmde_entete.vue';
import Cmde_ligne from '../pages/cmde_ligne.vue';
import Cmde_enter from '../pages/cmde_enter.vue';
import Cmde_enter_bar from '../pages/cmde_enter_bar.vue';
import Product_liste from '../pages/product_liste.vue';
import Product_find from '../pages/product_find.vue';

import Invt_liste from '../pages/invt_liste.vue';
import Invt_ligne from '../pages/invt_ligne.vue';
import Article_liste from '../pages/article_liste.vue';
import Invt_enter from '../pages/invt_enter.vue';
import Invt_enter_bar from '../pages/invt_enter_bar.vue';

var routes = [
  {
    path: '/',
    component: HomePage,
  },
  {
    path: '/about/',
    component: AboutPage,
  },
  {
    path: '/menu_inventaire/',
    component: Menu_inventaire,
  },
  {
    path: '/menu_synchro/',
    component: Menu_synchro,
  },
  {
    path: '/cmde_liste/',
    component: Cmde_liste,
  },
  {
    path: '/cmde_entete/',
    name: 'cmde_entete',
    component: Cmde_entete,
  },
  {
    path: '/cmde_ligne/',
    name: 'cmde_ligne',
    component: Cmde_ligne,
  },
  {
    path: '/invt_ligne/',
    name:'invt_ligne',
    component: Invt_ligne,
  },
  {
    path: '/cmde_enter/:id/ope/:op/',
    component: Cmde_enter,
  },
  {
    path: '/cmde_enter_bar/:id/ope/:op/',
    component: Cmde_enter_bar,
  },
  {
    path: '/invt_enter/:id/ope/:op/',
    component: Invt_enter,
  },
  {
    path: '/invt_enter_bar/:id/ope/:op/',
    component: Invt_enter_bar,
  },
  {
    path: '/product_liste/',
    component: Product_liste,
  },
  {
    path: '/article_liste/',
    component: Article_liste,
  },
  {
    path: '/product_find/',
    component: Product_find,
  },
  {
    path: '/invt_liste/',
    name: 'invt_liste',
    component: Invt_liste,
  },
  {
    path: '/catalog/',  
    component: CatalogPage,
  },
  {
    path: '/product/:id/',
    component: ProductPage,
  },
  {
    path: '/settings/',
    component: SettingsPage,
  },
  {
    path: '(.*)',
    //component: NotFoundPage,
    component: HomePage,
  },
];

export default routes;
