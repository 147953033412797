<template>
  <f7-page name="cmde_entete">
    <f7-navbar title="MOBI-STOCKS" subtitle="Entete de commande" back-link="Back">
      <f7-nav-right>
        <f7-link icon-ios="f7:close" icon-aurora="f7:close" icon-md="material:close" @click="openAnnulation"></f7-link>
      </f7-nav-right>  
    </f7-navbar>

    <f7-fab position="right-bottom" slot="fixed" text="Continuer"   @click="gotoLigne">
      <f7-icon ios="f7:add" aurora="f7:add" md="material:add"></f7-icon>
    </f7-fab>

    <f7-list no-hairlines-md>
      <f7-list-input label="Site" type="text" placeholder="Votre site"
        :value="edit_site" @input="edit_site = $event.target.value"
        clear-button
      ></f7-list-input>
      <f7-list-input
        label="Poste" type="text" placeholder="Votre poste"
        :value="edit_poste" @input="edit_poste = $event.target.value"
        clear-button
      ></f7-list-input>
      <f7-list-input label="Référence" type="text" placeholder="Votre référence de commande"
        :value="edit_refcde" @input="edit_refcde = $event.target.value"
        clear-button
      ></f7-list-input>
      <f7-list-input label="Observation" type="textarea" placeholder="Vos Observations"
       :value="edit_obs" @input="edit_obs = $event.target.value"
      >
      </f7-list-input>
    </f7-list>

<!--
    <f7-block >
      <f7-button large fill raised href="/cmde_ligne/" >Poursuivre</f7-button>
    </f7-block>
-->
  </f7-page>
</template>

<script>

export default {
  data: function () {
    return {
      edit_site: "",
      edit_poste: "",
      edit_refcde: "",
      edit_obs: "",
    };
  },
  methods: {
    openConfirm() {
        const app = this.$f7;
        app.dialog.confirm('Confirmez vous ?', () => {
          app.views.main.router.navigate( '/cmde_ligne/');
        });
    },
    openAnnulation (){
        const app = this.$f7;
        app.dialog.confirm('Confirmez vous l\'annulation ?', () => {
          app.views.main.router.navigate( '/cmde_liste/');
        });
    },
    gotoLigne () {
      const app = this.$f7;
      //let tableLigne = this.$f7.data.tableLigne;

      // Effacer les lignes de commande précédentes
      this.$f7.data.tableLigne = [];

      // Positionner les valeurs par défaut
      this.$f7.data.dflt_site =  this.edit_site;
      this.$f7.data.dflt_poste = this.edit_poste;

      debugger;
      app.views.main.router.navigate( '/cmde_ligne/');
    },
  }
};
</script>
