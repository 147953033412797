/*
import * as JsStoreWorker from "jsstore/dist/jsstore.worker.commonjs2";
window['JsStoreWorker'] = JsStoreWorker;
*/
import * as JsStore from "jsstore";

// Methode Asynchrone avec Webworker
const getWorkerPath = () => {

    // return dev build when env is development
    if (process.env.NODE_ENV === 'development') {
        return require("file-loader?name=scripts/[name].[hash].js!jsstore/dist/jsstore.worker.js");
        //return require("babel-loader?jsstore/dist/jsstore.worker.js");
        //return require("jsstore/dist/jsstore.worker.js");
    }
    else { // return prod build when env is production
        return require("file-loader?name=scripts/[name].[hash].js!jsstore/dist/jsstore.worker.min.js");
        //return require("babel-loader?jsstore/dist/jsstore.worker.min.js");
        //return require("jsstore/dist/jsstore.worker.min.js");
    }
};


const workerPath = getWorkerPath();
export const connection = new JsStore.Instance(new Worker(workerPath));


// Methode Synchrone sans Webworker
//export const connection = new JsStore.Instance( );

connection.setLogStatus(true);