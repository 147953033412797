<template>
  <f7-page name="menu_synchro">
    <f7-navbar title="MOBI-STOCKS" subtitle="Synchronisation" back-link="Back">
      <f7-nav-right>
        <f7-link icon-ios="f7:close" icon-aurora="f7:close" icon-md="material:close" @click="openAnnulation"></f7-link>
      </f7-nav-right>  
    </f7-navbar>
    <!-- <f7-block-title>Synchronisation</f7-block-title> -->

    <!-- Page content-->
    <f7-block>&nbsp;</f7-block>
    <f7-block ></f7-block>

    <f7-block >
          <f7-button large fill raised  @click="synchroReferencement" >Référencement</f7-button>
    </f7-block>
    <f7-block  v-if="call"><center>
      <f7-preloader color="blue"></f7-preloader></center>
    </f7-block>

  </f7-page>
</template>

<script>
import { ReferencementService } from "../service/referencement_service";

export default {
  name: "Marches",
  data: function() {
    return {
      resultat: null,
      call: false,
      dbcon: null
    };
  },
  methods: {
    synchroReferencement: async function() {
      const app = this.$f7;

      //this.call = true;

      const service = new ReferencementService();    /* connexion */
      
      this.resultat = await service.clearReferencement();
      console.log('MOBI-STOCK: Data cleared successfully');

      this.resultat = await service.syncReferencement( app );
      console.log('MOBI-STOCK: Data synchro successfully');

      //app.dialog.alert('Synchronisation réussie');

/*
       debugger;
        service.syncReferencement(app).then((value) =>  {
            debugger;
            console.log("DANEM  break point 1");
            //app.dialog.alert('Synchronisation réussie');
          }, (raison) => {
          // Rejet de la promesse
            app.dialog.alert('Synchronisation en anomalie');
          }
        );
*/

    },
    clearReferencement: async function() {
      this.resultat = await new ReferencementService().clearReferencement();
      console.log('data cleared successfully');
    },
    openAnnulation (){
        const app = this.$f7;
        app.views.main.router.navigate( '/');
    },
  }
};

</script>