import { connection } from "./jsstore_con";
import { DATA_TYPE } from "jsstore";

/*
[CODE_CLIENT] [nvarchar] (20) COLLATE French_CI_AS NOT NULL ,
		[MOBISTOCK] [nvarchar] (5) COLLATE French_CI_AS NOT NULL ,
		[NEGOSTOCK] [nvarchar] (5) COLLATE French_CI_AS NOT NULL ,
		[REFERENCEINTERNE] [nvarchar] (20) COLLATE French_CI_AS NOT NULL ,
		[DESIGNATIONINTERNE] [nvarchar] (100) COLLATE French_CI_AS NOT NULL ,
		[EAN] [nvarchar] (13) COLLATE French_CI_AS NOT NULL ,
		[REFERENCECLIENT] [nvarchar] (20) COLLATE French_CI_AS NOT NULL ,
		[DESIGNATIONCLIENT] [nvarchar] (100) COLLATE French_CI_AS NOT NULL ,		
		[CODEFOURNISSEUR] [nvarchar] (30) COLLATE French_CI_AS NOT NULL ,
		[DESFOURNISSEUR] [nvarchar] (100) COLLATE French_CI_AS NOT NULL ,
		[EANFOURNISSEUR] [nvarchar] (13) COLLATE French_CI_AS NOT NULL ,
		[CONFIDENTIALITE] [nvarchar] (20) COLLATE French_CI_AS NOT NULL


*/
const getDatabase = () => {

    const tblReferencement = {
        name: 'Referencement',
        columns: {
            id: {
                primaryKey: true
            },
            code_client: {
                notNull: true,
                dataType: DATA_TYPE.String
            },
            referenceinterne: {
                dataType: DATA_TYPE.String
            },
            designationinterne: {
                dataType: DATA_TYPE.String
            },
            ean: {
                dataType: DATA_TYPE.String
            },
            referenceclient: {
                dataType: DATA_TYPE.String
            },
            designationclient: {
                dataType: DATA_TYPE.String
            },
            codefournisseur: {
                dataType: DATA_TYPE.String
            },
            desfournisseur: {
                dataType: DATA_TYPE.String
            },
            eanfournisseur: {
                dataType: DATA_TYPE.String
            },
            confidentialite: {
                dataType: DATA_TYPE.String
            },
            img: {
                dataType: DATA_TYPE.String
            },
            cdts: {
                dataType: DATA_TYPE.Array
            },
            sites: {
                dataType: DATA_TYPE.Array
            }
        }
    };

    //notNull: false,
    const tblOrder = {
        name: 'tblOrder',
        columns: {
            id: {
                primaryKey: true,      /* pour gérer le numéro de commande !  */
                autoIncrement: true
            },
            num_order: {
                dataType: DATA_TYPE.String
            },
            code_operateur: {
                dataType: DATA_TYPE.String
            },
            code_contact: {
                dataType: DATA_TYPE.String
            },
            code_client: {
                dataType: DATA_TYPE.String
            },
            name_site: {
                dataType: DATA_TYPE.String
            },
            site: {
                dataType: DATA_TYPE.String
            },
            reference: {
                dataType: DATA_TYPE.String
            }, 
            comment: {
                dataType: DATA_TYPE.String
            },           
            datecreat: {
                notNull: true,
                dataType: DATA_TYPE.String
            },
            timecreat: {
                notNull: true,
                dataType: DATA_TYPE.String    //DATA_TYPE.DateTime
            },
            lignes: {
                dataType: DATA_TYPE.Array      /*   modulable */
            },         
            exporter: {
                notNull: true,
                dataType: DATA_TYPE.String,
                default: 'N'
            }
        },
        //version: 1 //Default version is 1.
    };

    //notNull: false,
    const tblInventory = {
        name: 'tblInventory',
        columns: {
            id: {
                primaryKey: true,      /* pour gérer le numéro de commande !  */
                autoIncrement: true
            },
            num_invt: {
                dataType: DATA_TYPE.String
            },
            num_type: {
                dataType: DATA_TYPE.String
            },
            session: {
                dataType: DATA_TYPE.String
            },
            code_operateur: {
                dataType: DATA_TYPE.String
            },
            code_contact: {
                dataType: DATA_TYPE.String
            },
            code_client: {
                dataType: DATA_TYPE.String
            },
            name_site: {
                dataType: DATA_TYPE.String
            },
            site: {
                dataType: DATA_TYPE.String
            },
            reference: {
                dataType: DATA_TYPE.String
            }, 
            comment: {
                dataType: DATA_TYPE.String
            },           
            datecreat: {
                notNull: true,
                dataType: DATA_TYPE.String
            },
            timecreat: {
                notNull: true,
                dataType: DATA_TYPE.String    //DATA_TYPE.DateTime
            },
            lignes: {
                dataType: DATA_TYPE.Array      /*   modulable */
            },         
            exporter: {
                notNull: true,
                dataType: DATA_TYPE.String,
                default: 'N'
            }
        },
        version: 2 //Default version is 1.
    };

    const dataBase = {
        name: "MOBI-STOCKS_DB",
        tables: [tblReferencement, tblOrder, tblInventory ]
    };
    return dataBase;
};


// Methode Asynchrone avec Webworker
export const initJsStore = async () => {
    const dataBase = getDatabase();
    return await connection.initDb(dataBase);
};


/*
// Methode Synchrone sans Webworker
export const initJsStore = () => {
    const dataBase = getDatabase();
    return connection.initDb(dataBase);
};
*/
