<template>
  <f7-page>
    <f7-navbar>
      <f7-nav-left>
       <f7-link icon-ios="f7:arrow_left" icon-aurora="f7:arrow_left" icon-md="material:arrow_back" @click.stop="$f7router.navigate('/cmde_ligne/')" ></f7-link>
      </f7-nav-left> 

      <f7-nav-title title="MOBI-STOCKS" subtitle="Recherche par filtre" ></f7-nav-title> 

      <f7-nav-right>
       <f7-link icon-ios="f7:close" icon-aurora="f7:close" icon-md="material:close" @click.stop="$f7router.navigate('/cmde_ligne/')" ></f7-link>
      </f7-nav-right>  

      <f7-subnavbar :inner="false">
        <div style="width:100%; margin:0;">
          <f7-block style="margin:0">
          <f7-row no-gap>
            <f7-col width="70">        
              <f7-searchbar
              search-container=".virtual-list"
              search-item="li"
              search-in=".item-title"
              placeholder="Chercher"
              :disable-button="!$theme.aurora"
              ></f7-searchbar>
            </f7-col>
            <f7-col width="30"><f7-button large fill raised  href="/cmde_enter_bar/tt/ope/scan/" >&nbsp;&nbsp;Caméra&nbsp;&nbsp;</f7-button></f7-col>
          </f7-row>
          </f7-block> 
        </div>
      </f7-subnavbar>
    </f7-navbar>
    <f7-block class="searchbar-hide-on-search">
      <p>Faites votre recherche par <strong>Code Article</strong>, <strong>Désignation</strong> ou par la <strong>Caméra</strong>.</p>
    </f7-block>
    <f7-list class="searchbar-not-found">
      <f7-list-item title="Rien trouvé"></f7-list-item>
    </f7-list>
    <f7-list
      class="searchbar-found"
      virtual-list
      :virtual-list-params="{ items, searchAll, renderExternal, height: $theme.ios ? 63 : ($theme.md ? 73 : 46)}"
    >
      <ul>
        <!-- 
          :title="item.referenceinterne"
          :title="item.designationinterne"
          :subtitle="item.designationinterne"
          link="#"
          :link="`/product/${item.referenceinterne}/`"
          <div slot="subtitle" style="white-space: normal">{{item.designationinterne}}</div>
          @click="openFiche( item.referenceinterne )"
        -->
        <f7-list-item
          v-for="(item, index) in vlData.items" :key="index"
          :header="item.referenceinterne"
          :style="`top: ${vlData.topPosition}px`"
          :virtual-list-index="items.indexOf(item)"
          :link="`/cmde_enter/${item.referenceinterne}/ope/add/`"
        >
        <div slot="title" style="white-space: normal; font-size:14px">{{item.designationinterne}}</div>
        </f7-list-item>
      </ul>
    </f7-list>
  </f7-page>  
</template>

<script>
  //import { f7Navbar, f7Page, f7List, f7ListItem, f7Subnavbar, f7Searchbar, f7Block } from 'framework7-vue';
  export default {
    data() {
      debugger;
      this.$f7.data.referencement = JSON.parse(localStorage.getItem('referencement'));
      this.$f7.data.search = JSON.parse(localStorage.getItem('search'));

      return {
        items: this.$f7.data.search,
        vlData: {
          items: [],
        },
      };
    },  
    mounted () {
      /*
      var self = this;
      this.$f7ready((f7) => {
      });
      */
      //this.refreshReferencement();
    },
    methods: {

      searchAll(query, items) {
        const found = [];
        for (let i = 0; i < items.length; i += 1) {
/*
          if (items[i].referenceinterne.toLowerCase().indexOf(query.toLowerCase()) >= 0 
            || items[i].designationinterne.toLowerCase().indexOf(query.toLowerCase()) >= 0 
            || query.trim() === '') found.push(i);
*/
          if (items[i].referenceinterne.indexOf(query) >= 0 
            || items[i].referenceclient.toLowerCase().indexOf(query.toLowerCase()) >= 0 
            || items[i].designationinterne.toLowerCase().indexOf(query.toLowerCase()) >= 0 
            || query.trim() === '') found.push(i);
        }
        return found; // return array with mathced indexes
      },

      renderExternal(vl, vlData) {
        this.vlData = vlData;
      }, 
    },

    mounted: function() {
  
    },  
  };
</script>