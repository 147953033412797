<template>
  <f7-page name="menu_inventaire">
    <f7-navbar title="MOBI-STOCKS" subtitle="Inventaires"  back-link="Back">
      <f7-nav-right>
        <f7-link icon-ios="f7:close" icon-aurora="f7:close" icon-md="material:close" @click="openAnnulation"></f7-link>
      </f7-nav-right>  
    </f7-navbar>
     <!-- <f7-block-title>MOBI-STOCKS / Inventaires</f7-block-title> -->

    <!-- Page content-->
    <f7-block>&nbsp;</f7-block>
    <f7-block ></f7-block>

    <f7-block >
          <f7-button large fill raised  @click.stop="openInventory0" >Inventaire Simple&nbsp;&nbsp; <f7-badge v-if='nbInvent0 > 0'  color="red">{{nbInvent0}}</f7-badge></f7-button>
    </f7-block>

    <f7-block >
          <f7-button large fill raised @click.stop="openInventory1">Inventaire pour Commande&nbsp;&nbsp; <f7-badge v-if='nbInvent1 > 0'  color="red">{{nbInvent1}}</f7-badge></f7-button>
    </f7-block>
  </f7-page>
</template>

<script>

import { InventoryService } from "../service/Inventory_service";

export default {

  data: function () {
    return {
      connexion: null,     // centraliser la connexion !!!
      nbInvent0: 0,
      nbInvent1: 0
    };
  },

  mounted () {

    this.connexion = new InventoryService();
    this.countInventory();
  },

  methods: {
    openAnnulation (){
       // const app = this.$f7;
        this.$f7router.navigate('/');
    },

    countInventory: async function() {
        debugger;
        this.nbInvent0 = await this.connexion.countInventoryType( "0");
        this.nbInvent1 = await this.connexion.countInventoryType( "1");
    },

    openInventory0(){
      //const app = this.$f7;
      this.$f7.data.Invt_num_type = "0";
      localStorage.setItem('Invt_num_type', this.$f7.data.Invt_num_type);

      this.$f7router.navigate('/invt_liste/');
    },

    openInventory1(){
      //const app = this.$f7;
      this.$f7.data.Invt_num_type = "1";
      localStorage.setItem('Invt_num_type', this.$f7.data.Invt_num_type);

      this.$f7router.navigate('/invt_liste/');
    },
    
  }
};
</script>