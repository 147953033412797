<template>
  <f7-page name="cmde_ligne_update" :page-content="false" @page:beforeremove="onPageBeforeRemove">
    <f7-navbar title="MOBI-STOCKS" subtitle="Fiche Article" >
      <f7-nav-right>
        <f7-link icon-ios="f7:close" icon-aurora="f7:close" icon-md="material:close" back></f7-link>
      </f7-nav-right>  
    </f7-navbar>
    <f7-toolbar position="top" tabbar>
      <f7-link tab-link="#tabcde-1" tab-link-active text="Saisie" ></f7-link>
      <f7-link tab-link="#tabcde-2" text="Informations" ></f7-link>
    </f7-toolbar>
    <f7-tabs>
      <f7-tab id="tabcde-1" class="page-content" tab-active>
        <f7-block class="mydef">
          <f7-row no-gap>
            <f7-col width="30"><strong>Code</strong></f7-col>
            <f7-col width="70"><div style="font-size: medium">{{product.referenceinterne }}</div></f7-col>
          </f7-row>
          <f7-row no-gap>
            <f7-col width="30"><strong>Désignation</strong></f7-col>
            <f7-col width="70"><div>{{product.designationinterne}}</div></f7-col>
          </f7-row>
          <f7-row no-gap>
            <f7-col width="30"><strong>Info</strong></f7-col>
            <f7-col width="70"><div>{{visu_info}}</div></f7-col>
          </f7-row>
          <f7-row  v-if="gest_site" no-gap>
            <f7-col width="30"><strong>Site</strong></f7-col>
            <f7-col width="70">
              <f7-input type="select" defaultValue="" inputStyle="font-size:large" :disabled="edit_site_blq" :value="edit_site" @input="edit_site = $event.target.value" @change="upd_poste">
                <option v-for="site in sites" :key="site.code_site" :value="site.code_site">{{site.name_site}}</option>   
              </f7-input>
              <!-- <f7-input type="text" inputStyle="font-size: medium" :value="edit_site" @input="edit_site = $event.target.value" clear-button></f7-input> -->
            </f7-col>
          </f7-row>
          <f7-row no-gap>
            <f7-col width="30"><br></f7-col>
            <f7-col width="70"></f7-col>
          </f7-row>
          <f7-row v-if="gest_poste" no-gap>
            <f7-col width="30"><strong>Poste</strong></f7-col>
            <f7-col width="70">
              <f7-input type="select" defaultValue="" inputStyle="font-size:large" :value="edit_poste" @input="edit_poste = $event.target.value">
                <option v-for="poste in postes" :key="poste.code" :value="poste.code">{{poste.libelle}}</option>   
              </f7-input>
             </f7-col>
          </f7-row>
          <f7-row no-gap>
            <f7-col width="30"><br></f7-col>
            <f7-col width="70"></f7-col>
          </f7-row>
          <f7-row no-gap>
            <f7-col width="30"><strong>Condt</strong></f7-col>
            <f7-col width="70">
              <f7-input type="select" inputStyle="font-size:large" :value="edit_condition" @input="edit_condition = $event.target.value">
                <option v-for="cdt in cdts" :key="cdt.code" :value="cdt.code">{{cdt.libelle}}</option>   
              </f7-input>
            </f7-col>
          </f7-row>
          <f7-row no-gap>
            <f7-col width="30"><br></f7-col>
            <f7-col width="70"></f7-col>
          </f7-row>
          <f7-row no-gap>
            <f7-col width="30"><strong>Quantité</strong></f7-col>
            <f7-col width="70">
               <f7-stepper ref='stepperQte1' fill :value="edit_quantite" manual-input-mode :min=qte_mini :max=1000 :step=qte_step :autorepeat="true" :autorepeat-dynamic="true" @stepper:change="setQuantite"></f7-stepper>
            </f7-col>
          </f7-row>    
        </f7-block>
  
        <f7-block class="mydef" >
          <f7-button  v-if="operation === 'upd'" large fill raised  @click="before_update_ref" >Modifier</f7-button>
          <f7-button v-else large fill raised  @click="before_add_ref" >Ajouter à votre liste</f7-button>
        </f7-block>  
      </f7-tab>

      <f7-tab id="tabcde-2" class="page-content">
        <f7-block class="mydef">
          <f7-row no-gap>
            <f7-col width="30"><strong>Code</strong></f7-col>
            <f7-col width="70">{{product.referenceinterne }}</f7-col>
          </f7-row>
          <f7-row no-gap>
           <f7-col width="30"><strong>Désignation</strong></f7-col>
           <f7-col width="70">{{product.designationinterne}}</f7-col>
          </f7-row>
          <f7-row no-gap>
            <f7-col width="30"><strong>Référence Client</strong></f7-col>
            <f7-col width="70">{{product.referenceclient}}</f7-col>
          </f7-row>
          <f7-row no-gap>
            <f7-col width="30"><strong>Désignation Client</strong></f7-col>
            <f7-col width="70">{{product.designationclient}}</f7-col>
          </f7-row>
          <f7-row no-gap>
            <f7-col width="30"><strong>Code Fournisseur</strong></f7-col>
            <f7-col width="70">{{product.codefournisseur}}</f7-col>
          </f7-row>
          <f7-row no-gap>
            <f7-col width="30"><strong>Désignation Fournisseur</strong></f7-col>
            <f7-col width="70">{{product.desfournisseur}}</f7-col>
          </f7-row>
          <f7-row no-gap>
            <f7-col width="30"><strong>EAN Fournisseur</strong></f7-col>
            <f7-col width="70">{{product.eanfournisseur}}</f7-col>
          </f7-row>
        </f7-block>

        <f7-card class="mobi-card-header-pic">
          <f7-card-header class="no-border" valign="bottom" :style="`background-image:url(${product.img})`">
          </f7-card-header>
        </f7-card>        
      </f7-tab>
    </f7-tabs>

    <f7-actions ref="actionsBoublonsCde">
      <f7-actions-group>
        <f7-actions-label bold>Référence déjà saisie !<br>{{doublon_text}}<br>Poursuivre ?</f7-actions-label>
        <f7-actions-button @click="remplace_ref('upd')" ><strong>Oui ></strong> Remplacez la quantité précédente.</f7-actions-button>
        <f7-actions-button @click="remplace_ref('add')"><strong>Oui ></strong> Ajoutez à la quantité précédente.</f7-actions-button>
        <f7-actions-button color="red" @click="remplace_ref('ann')" ><strong>Non ></strong> Annulez l'opération.</f7-actions-button>
      </f7-actions-group>
    </f7-actions>

  </f7-page>
</template>

<script>

  export default {
    data: function () {
      debugger;
      let localId = this.$f7route.params.id;
      let opId = this.$f7route.params.op;
      
      /*  voir si possible de déplacer dans App.vue */
      this.$f7.data.dflt_site = localStorage.getItem("dflt_site");
      this.$f7.data.dflt_site_name = localStorage.getItem("dflt_site_name");
      this.$f7.data.dflt_poste = localStorage.getItem("dflt_poste");
      this.$f7.data.tableLigne = JSON.parse(localStorage.getItem('tableLigne'));

      let local_ligneId = -1;
      let local_refId ="";
      let local_site = "";
      let local_info = "";
      let local_poste = "";
      let local_condition = "";
      let local_quantite = 1;
      let local_site_blq = false;

      let ini_cdt= "";
      let init_uv = 1;
      let int_step=1;

      if ( opId == "add"  ){  
        // Ajout
        local_refId = localId;
      }
      else {
        // Mise à jour
        local_ligneId = localId;
        let theligne = this.$f7.data.tableLigne[local_ligneId];  //  localId  c'est id de la ligne !!
        local_refId = theligne.ref;
        local_site = theligne.site;
        local_poste = theligne.poste;
        local_condition = theligne.cdt;
        local_quantite = parseInt (theligne.qte);
      }

      //** partie commune ************

      // récupère le référencement
      let items= this.$f7.data.referencement;

      // récupère la liste des sites.
      let init_sites = JSON.parse(localStorage.getItem('societe'));
      let init_postes= [];

      // Détecte l'affichage de site
      let init_gest_site = true;
      let init_gest_poste = true;
      if  ( init_sites.length == 0) {
        init_gest_site = false;
        init_gest_poste = false;
      }

      // si affichage et pas encore positionner mettre valeur par défaut
      if (init_gest_site ){
         if ( this.$f7.data.dflt_site == ""){
          local_site = "";
          init_gest_poste = false;

         }
         else{
          //retrouver index
          let index;
          local_site_blq = true;
          local_site = this.$f7.data.dflt_site;  // site par défaut

          // retouver l'index puis prendre la valeur
          for (let i = 0; i < init_sites.length; i += 1) {
            if (init_sites[i].code_site == this.$f7.data.dflt_site ) {
              index = i;
              break;
            }
          }

          init_postes= init_sites[index].postes;

          //caché si pas de poste
          if ( init_postes.length > 0){
            init_gest_poste = true;
            // retrouver valeur poste par défaut
            if ( opId == "add"  ){  
              if (this.$f7.data.dflt_poste == ""){
                  // prendre le premier de la liste
                   local_poste = init_postes[0].code;
              }
              else
                local_poste = this.$f7.data.dflt_poste ;
            }
          }
          else
            init_gest_poste = false;
         }
      }

      let init_product= [];
      let init_cdts= [];
      let init_qte_mini=1;
      let init_qte_step=1;

      debugger;
      for (let i = 0; i < items.length; i += 1) {
        //recherche séquentiel
        if (items[i].referenceinterne.indexOf(local_refId) >= 0 ) {
          // Si trouvé
          init_product = items[i];
          init_cdts = items[i].cdts;

          // Ajout gestion au site ou est le site !
          let sites_op = items[i].sites;
          let site_trouver= false;
          let index2 = -1;

          if ( sites_op.length > 0)  // tester si au moins un site
          {
            for (let id2 = 0; id2 < sites_op.length; id2 += 1) {
              if (sites_op[id2].site == local_site ) {
                init_qte_mini=  parseInt (sites_op[id2].mini);
                init_qte_step= parseInt (sites_op[id2].unite);
                local_info = sites_op[id2].info;

                index2 = id2;
                site_trouver = true;
                break;
              }
            }
          }

          if (site_trouver == false )
          {
            init_qte_mini=  1;
            init_qte_step= 1;
          }

          if ( opId == "add"){
            if ( site_trouver)
            {
              local_condition = sites_op[index2].dflt_cdt;
              if ( local_condition == null){
                  local_condition = init_cdts[0].code; // Prendre le 1er de la liste
              }
              local_quantite = parseInt (sites_op[index2].mini);

              let existe_pas = true;

              // a l'ajout testé si conditionnement existe 
              // NOTA: Pour l'instant  en modif on ne touche à rien
              if ( init_cdts.length > 0)  // tester si au moins un site
              {
                for (let id3 = 0; id3 < init_cdts.length; id3 += 1) {
                  if (init_cdts[id3].code == local_condition ) {
                    existe_pas = false;
                    break;
                  }
                }
              }

              if ( existe_pas ){
                local_condition = init_cdts[0].code; 
              }
            }
            else
            {
              local_condition = init_cdts[0].code; // Prendre le 1er de la liste
              local_quantite = 1;
            }
          }
          break;
        }
      }

      return {
        operation : opId,
        gest_site : init_gest_site,
        gest_poste : init_gest_poste,
        numero_ligne: local_ligneId,
        edit_ref : local_refId,
        visu_info: local_info,
        edit_site: local_site ,
        edit_site_blq : local_site_blq,
        sites:  init_sites,
        edit_poste: local_poste,
        edit_poste_entree: local_poste,
        postes: init_postes,
        edit_condition: local_condition,
        edit_quantite: local_quantite,
        cdts: init_cdts,
        product: init_product,
        qte_mini: init_qte_mini,
        qte_step: init_qte_step,
        position_dbl : -1,
        doublon_text : "hello"
      };
    },
    methods: {

      onPageBeforeRemove() {
        this.$refs.actionsBoublonsCde.close();
      },

      upd_poste (){
        let sites= this.sites;
        let index;
        // retouver l'index puis prendre la valeur
        for (let i = 0; i < sites.length; i += 1) {
          if (sites[i].code_site == this.edit_site ) {
            index = i;
            break;
          }
        }

        this.postes=  this.sites[index].postes;

        // selection par défaut 1er ligne
        if ( this.postes.length > 0){
          this.gest_poste = true;
          this.edit_poste= this.postes[0].code;
        }
        else {
           this.gest_poste = false;
           this.edit_poste="";
        }

        //changer paramètre fct du site
        let local_param =  this.product.sites;
        let site_trouver = false;
        let index2 = -1;
        if ( local_param.length > 0)  // tester si au moins un site
        {
            for (let id2 = 0; id2 < local_param.length; id2 += 1) {
              if (local_param[id2].site == this.edit_site ) {
                this.visu_info = local_param[id2].info;
                this.qte_mini = parseInt (local_param[id2].mini);
                this.qte_step = parseInt (local_param[id2].unite);
                this.edit_quantite = parseInt (local_param[id2].mini);
                this.edit_condition = local_param[id2].dflt_cdt;

                if ( this.edit_condition  == null ){
                  this.edit_condition = this.cdts[0].code; // Prendre le 1er de la liste
                }

                index2 = id2;
                site_trouver = true;
                break;
              }
            }
        }

        if (site_trouver == false )
        {
          this.visu_info = "";
          this.qte_mini =  1;
          this.qte_step = 1;
          this.edit_quantite = 1;

          // prendre 1er cdt
          this.edit_condition = this.cdts[0].code;
        }

      },

      setQuantite(value) {
        this.edit_quantite = value;
      },    
      
      before_add_ref(){
        const app = this.$f7;
        
        let site =  this.edit_site;
        if ( site.length == 0 || site== ""){
            app.dialog.alert('Veuillez sélectionner le site ! ');
            return; 
        }

        //checker si ref déjà présente !!
        var items = this.$f7.data.tableLigne;
        let ifpresent = false;

        let reference =  this.edit_ref;
        let poste = this.edit_poste;

        // d'abord cherche en locale
        for (let i = 0; i < items.length; i += 1) {
          if ( (items[i].ref.indexOf(reference) >= 0) && (items[i].poste.indexOf(poste) >= 0)  ) {
                ifpresent = true;
                this.position_dbl = i;
                break;
          }
        }

        // QUID ???  pour le conditionnement ?

        //puis  dans la Base
        if( ifpresent ) {
          //positionner le texte du doublon
          this.doublon_text= items[this.position_dbl].qte + " x " +  items[this.position_dbl].cdtlbl + " - " +  items[this.position_dbl].postelbl;
          this.$refs.actionsBoublonsCde.open();
        }
        else
          this.add_ref();
      },

      add_ref () {
          debugger;
          // ajouter au tableau Globale
          let tableLigne = this.$f7.data.tableLigne;

          // retrouver le libelle du conditionnement sélectionné
          let cdtlbl_ = "";
          let cdts= this.cdts;
          for (let i = 0; i < cdts.length; i += 1) {
              if (cdts[i].code == this.edit_condition ) {
                cdtlbl_ = cdts[i].libelle;
                break;
              }
          }

          // retrouver le libelle du poste ( voir comment améliorer)
          let postelbl_ = "";
          let postes= this.postes;
          for (let i = 0; i < postes.length; i += 1) {
              if (postes[i].code == this.edit_poste ) {
                postelbl_ = postes[i].libelle;
                break;
              }
          }

          //tableLigne.push( {
          tableLigne.unshift( {
            ref: this.product.referenceinterne,
            des: this.product.designationinterne,
            site: this.edit_site,
            poste: this.edit_poste,
            postelbl: postelbl_,
            cdt: this.edit_condition,
            cdtlbl: cdtlbl_,  // libelle retrouvé  a améliorer
            qte: this.edit_quantite
          } );
        
          // Positionner la valeurs par défaut du site 
          this.$f7.data.dflt_site = this.edit_site;

          // retouver le nom du site par défaut
          let sites= this.sites;
          for (let i = 0; i < sites.length; i += 1) {
              if (sites[i].code_site == this.edit_site ) {
                this.$f7.data.dflt_site_name = sites[i].name_site;
                break;
              }
          }

          // Positionner la valeurs par défaut du poste 
          this.$f7.data.dflt_poste = this.edit_poste;

          // Synchro Update LocalStorage
          localStorage.setItem('tableLigne', JSON.stringify(tableLigne));
          localStorage.setItem('dflt_site', this.$f7.data.dflt_site);
          localStorage.setItem('dflt_site_name',  this.$f7.data.dflt_site_name);
          localStorage.setItem('dflt_poste',  this.$f7.data.dflt_poste);

          // fermer la fenetre   go back
          this.$f7router.back();
          //this.$f7router.back('/barcode_scan/', {force: true});  // David Test

      },

      remplace_ref (operation) {

        if ( operation == 'ann'){
          // fermer la page
          this.$f7router.back();
          return;
        }

        debugger;
        var items = this.$f7.data.tableLigne;
        var myref = this.product.referenceinterne ;  //Référence à modifier

        let cdtlbl_ = "";
        let cdts= this.cdts;

        // retrouver le libelle du conditionnement
        for (let i = 0; i < cdts.length; i += 1) {
          if (cdts[i].code == this.edit_condition ) {
            cdtlbl_ = cdts[i].libelle;
            break;
          }
        }

        // mettre à jour
        items[this.position_dbl].cdt = this.edit_condition;
        items[this.position_dbl].cdtlbl = cdtlbl_;   // libelle retrouvé  a améliorer

        let qte_tmp = items[this.position_dbl].qte;

        if ( operation == "add") {
          items[this.position_dbl].qte = qte_tmp + this.edit_quantite;
        }
        else {
          items[this.position_dbl].qte = this.edit_quantite;
        }
        
        // Positionner la valeurs par défaut du site 
        this.$f7.data.dflt_site = this.edit_site;

        // retouver le nom du site par défaut
        let sites= this.sites;
        for (let i = 0; i < sites.length; i += 1) {
              if (sites[i].code_site == this.edit_site ) {
                this.$f7.data.dflt_site_name = sites[i].name_site;
                break;
              }
        }

        // Positionner la valeurs par défaut du poste 
        this.$f7.data.dflt_poste = this.edit_poste;

        //  enregistrer table
        localStorage.setItem('tableLigne', JSON.stringify(items));

        // si issu de modif alors  effacer la ligne actuelle
        if ( this.operation == "upd"){
          items.splice(this.numero_ligne, 1);
        }

        // fermer la page
        this.$f7router.back();
      }, 

      before_update_ref(){
        debugger;
        // Détecter si changement de poste
        if ( this.edit_poste == this.edit_poste_entree )
        {
            this.update_ref ();
            return;
        }

        // détecter si doublon !
        var items = this.$f7.data.tableLigne;
        let ifpresent = false;

        let reference =  this.edit_ref;
        let poste = this.edit_poste;

        // Cherche en locale
        for (let i = 0; i < items.length; i += 1) {
          if ( (items[i].ref.indexOf(reference) >= 0) && (items[i].poste.indexOf(poste) >= 0)  ) {
            if ( i != this.numero_ligne  ) {
              ifpresent = true;
              this.position_dbl = i;
              break;
            }
          }
        }

        //puis  dans la Base
        if( ifpresent ) {
      
          //positionner le texte du doublon
          this.doublon_text= items[this.position_dbl].qte + " x " +  items[this.position_dbl].cdtlbl + " - " +  items[this.position_dbl].postelbl;
          this.$refs.actionsBoublonsCde.open();
        }
        else
          this.update_ref();
      },


      update_ref () {
          var items = this.$f7.data.tableLigne;
          var myref = this.product.referenceinterne ;  //Référence à modifier

          // retrouver le libelle du conditionnement
          let cdtlbl_ = "";
          let cdts= this.cdts;
          for (let i = 0; i < cdts.length; i += 1) {
              if (cdts[i].code == this.edit_condition ) {
                cdtlbl_ = cdts[i].libelle;
                break;
              }
          }


          // retrouver le libelle du poste ( voir comment améliorer)
          let postelbl_ = "";
          let postes= this.postes;
          for (let i = 0; i < postes.length; i += 1) {
              if (postes[i].code == this.edit_poste ) {
                postelbl_ = postes[i].libelle;
                break;
              }
          }

          // mettre à jour
          items[this.numero_ligne].site = this.edit_site;
          items[this.numero_ligne].poste = this.edit_poste;
          items[this.numero_ligne].postelbl = postelbl_;   // libelle retrouvé
          items[this.numero_ligne].cdt = this.edit_condition;
          items[this.numero_ligne].cdtlbl = cdtlbl_;   // libelle retrouvé  a améliorer
          items[this.numero_ligne].qte = this.edit_quantite;
        
          // Positionner la valeurs par défaut du site 
          this.$f7.data.dflt_site = this.edit_site;

          // retouver le nom du site par défaut
          let sites= this.sites;
          for (let i = 0; i < sites.length; i += 1) {
              if (sites[i].code_site == this.edit_site ) {
                this.$f7.data.dflt_site_name = sites[i].name_site;
                break;
              }
          }

          // Positionner la valeurs par défaut du poste 
          this.$f7.data.dflt_poste = this.edit_poste;

          // Synchro Update LocalStorage
          localStorage.setItem('tableLigne', JSON.stringify(items));
          localStorage.setItem('dflt_site', this.$f7.data.dflt_site);
          localStorage.setItem('dflt_site_name',  this.$f7.data.dflt_site_name);
          localStorage.setItem('dflt_poste',  this.$f7.data.dflt_poste);

          debugger;
          // fermer la page
          if ( this.operation == "upd"){
            //this.$f7router.back({force: true, ignoreCache: true, reload: true});
            this.$f7router.back();
          }
          else{
            this.$f7router.back();
          }

          //{url: 'index.html', force: true, ignoreCache: true,reload: true}          
      },
    },
    mounted: function() {
        this.$refs.stepperQte1.$el.querySelector('input').setAttribute('type', 'number');

        //this.$refs.stepperQte1.$el.querySelector('input').focus();
        //this.$refs.stepperQte1.$el.querySelector('input').select();
    },  
  
  };
</script>

<style>
.mobi-card-header-pic .card-header {
    height: 50vw;
    background-size: contain;
    background-position: center;
    background-repeat:no-repeat;
    color: #fff;
}

.mydef {
    margin: 12px 0;
}
</style>


