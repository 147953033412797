// Import Vue
import Vue from 'vue';

/*
// Import Framework7
import Framework7 from 'framework7/framework7-lite.esm.bundle.js';

// Import Framework7-Vue Plugin
import Framework7Vue from 'framework7-vue/framework7-vue.esm.bundle.js';
*/


// Import Framework7
import Framework7 from 'framework7/framework7-lite.esm.mybundle.js';

// Import Framework7-Vue Plugin
import Framework7Vue from 'framework7-vue/framework7-vue.esm.mybundle.js';


/*
// Import Framework7
import Framework7 from 'framework7/framework7-lite.js';

// Import Framework7-Vue Plugin
import { f7App, f7Navbar, f7Page, f7List, f7ListItem, f7Subnavbar,  f7Segmented, f7Button, f7Searchbar, f7Block, f7BlockTitle, f7BlockHeader, f7BlockFooter, f7Row, f7Col, f7Stepper, f7NavRight, f7Link,  f7Popup, f7Card, f7CardContent, f7Icon  } from 'framework7-vue';
*/


//import VueQuagga from 'vue-quaggajs';
import { StreamBarcodeReader } from "vue-barcode-reader";

// Import Framework7 Styles
import 'framework7/css/framework7.bundle.css';

// Import Icons and App Custom Styles
import '../css/icons.css';
import '../css/app.scss';

// Import App Component
import App from '../components/app.vue';

// Init Framework7-Vue Plugin
Framework7.use(Framework7Vue);

// register component 'zxing plugin'
Vue.use(StreamBarcodeReader);
Vue.component('StreamBarcodeReader', StreamBarcodeReader);

// Init App
new Vue({
  el: '#app',
  render: (h) => h(App),

  // Register App Component
  components: {
    app: App
  },
  
  methods: {
    onBackKeyDown: function() {  
    }
  },

  mounted() {
    document.addEventListener('backbutton', () => {
         alert('back')
          // on device back button go back
          //this.$router.back()
    }, false)
    
  },

});