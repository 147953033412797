import { connection } from "./jsstore_con";

export class OrderService {

    constructor() {
         
        this.tableName = "tblOrder";
    }

    getDbVersion ( db_name){
        return connection.getDbVersion(db_name)
    }

    countOrder(){
        return connection.count({
            from: "tblOrder",
        })
    }

    getOrder() {
        return connection.select({
            from: "tblOrder",
        })
    }

    addOrder(cde) {
        return connection.insert({
            into: "tblOrder",
            values: [cde],
            return: true
        })
    }

    getOrderById(id) {
        return connection.select({
            from: "tblOrder",
            where: {
                id: id
            }
        })
    }

    removeOrder(id) {
        return connection.remove({
            from: "tblOrder",
            where: {
                id: id
            }
        })
    }

    clearOrder() {
        //debugger;
        return connection.clear("tblOrder")
    }

    updateOrdersById(id, updateData) {
        return connection.update({
            in: "tblOrder",
            set: updateData,
            where: {
                id: id
            }
        })
    }
}