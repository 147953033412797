<template>
  <f7-page name="cmde_ligne" @page:beforein="openCharger"  @page:beforeout="onPageBeforeRemove">
    <f7-navbar title="MOBI-STOCKS" subtitle="Mon panier de commande" >
      <f7-nav-right>
        <f7-link icon-ios="f7:close" icon-aurora="f7:close" icon-md="material:close" @click="openAnnulation"></f7-link>
      </f7-nav-right>  
    </f7-navbar>
    <f7-block>&nbsp;{{site_name}}</f7-block> 

    <f7-fab position="right-top" slot="fixed" @click="openRecherche">
      <f7-icon ios="f7:add" aurora="f7:add" md="material:add"></f7-icon>
    </f7-fab>

    <f7-fab position="center-bottom" slot="fixed" color="green" @click="openTerminer">
      <f7-icon ios="f7:check" aurora="f7:check" md="material:check"></f7-icon>
    </f7-fab>
    
    <f7-list>
      <f7-list-item
        v-for="(link, index) in $f7.data.tableLigne" :key="index"
        :link="`/cmde_enter/${index}/ope/upd/`" no-chevron>   
        <f7-link color="red" icon-f7="trash"  @click.stop="openSupprimer( index )" ></f7-link>
        <div slot="title" style="white-space: normal; font-size:14px">{{link.ref}} : <strong>{{link.qte}}</strong> x <strong>{{link.cdtlbl}}</strong> - {{link.postelbl}}<br>{{link.des}}</div>
      </f7-list-item>
    </f7-list>
  </f7-page>
</template>

<script>
  import { OrderService } from "../service/Order_service";

  export default {
    data() {
      debugger;
      this.$f7.data.dflt_site = localStorage.getItem("dflt_site");
      this.$f7.data.dflt_site_name = localStorage.getItem("dflt_site_name");
      this.$f7.data.dflt_poste = localStorage.getItem("dflt_poste");

      this.$f7.data.tableLigne = JSON.parse(localStorage.getItem('tableLigne'));
      this.$f7.data.newOrder =  localStorage.getItem("newOrder");
      this.$f7.data.currentOrder = localStorage.getItem("currentOrder");
      this.$f7.data.ifInvt =  localStorage.getItem("ifInvt");

      return {
        //tableLigne: this.$f7.data.tableLigne,
        site_name: this.$f7.data.dflt_site_name,
        readyToClose : false,
        connexion: null,     // centraliser la connexion !!!
        numcompte: "",
        numoperateur:""
       };
    },  
    mounted () {
      this.connexion = new OrderService();
      this.numcompte =  localStorage.getItem("glb_numcompte");
      this.numoperateur =  localStorage.getItem("glb_login");
    },
    methods: {

      onPageBeforeRemove(){
          debugger;
          /*
          const app = this.$f7;
          if ( this.readyToClose == false ){
            app.dialog.confirm('Warning ?');
          }
          */
      },

      openCharger(){
          debugger;
          this.readyToClose = false;
      },

      openScan (){
        const app = this.$f7;
        this.readyToClose = true;
        app.views.main.router.navigate( '/barcode_scan/');
      },

      openRecherche (){
        const app = this.$f7;
        this.readyToClose = true;
        if ( 1 )
          app.views.main.router.navigate( '/product_liste/');
        else
          app.views.main.router.navigate( '/product_find/');
      },

      openAnnulation (){
        const app = this.$f7;
        this.readyToClose = true;
        if ( app.data.tableLigne.length > 0 ){
          app.dialog.confirm('Confirmez-vous l\'annulation de toutes vos modifications ?', () => {
            app.views.main.router.navigate( '/cmde_liste/');
          });
        }
        else{
           app.views.main.router.navigate( '/cmde_liste/');
        }
      },

      openTerminer (){
        const app = this.$f7;
        if ( app.data.tableLigne.length > 0 ){
          app.dialog.confirm('Confirmez-vous la validation de votre saisie ?', () => {
            //  faire sauvegarde ici
            this.readyToClose = true;
            if ( app.data.newOrder == true || app.data.newOrder == "true"){
              this.openSaveOrder().then((value) =>  {
                app.views.main.router.navigate( '/cmde_liste/');
              }, (err) =>  {
                app.dialog.alert('Erreur: Enregistrement non effectué !',  () => { app.views.main.router.navigate( '/cmde_liste/'); });                
              });
            }
            else {
              this.openUpdateOrder().then((value) =>  {
                app.views.main.router.navigate( '/cmde_liste/');
              }, (err) =>  {
                app.dialog.alert('Erreur: Enregistrement non effectué !',  () => { app.views.main.router.navigate( '/cmde_liste/'); });                
              });
            }
          });
        }
        else
        {
          app.dialog.alert('Votre commande est vide !');
        }
      },

      openSupprimer( indexToDelete ) {
         debugger;
          var app = this.$f7;
          app.dialog.confirm('Confirmez-vous la suppression de cette ligne ? ',  () => {
            app.data.tableLigne.splice(indexToDelete, 1);

            // Synchro Update LocalStorage
            localStorage.setItem('tableLigne', JSON.stringify(app.data.tableLigne));
          });
      },

      openSaveOrder: async function (){
        debugger;
        const app = this.$f7;
        var tableLigne = app.data.tableLigne;
        const d = new Date();
        const ndate = ("0" + d.getDate()).slice(-2) + "-" + ("0"+(d.getMonth()+1)).slice(-2) + "-" + d.getFullYear();
        const ntime = ("0" + d.getHours()).slice(-2) + ":" + ("0" + d.getMinutes()).slice(-2);
        var timestamp= d.getFullYear()+d.getMonth()+d.getDate()+d.getHours()+d.getMinutes()+d.getSeconds() ;
        
        // retrouver le libelle du site
        var sitelbl_ = this.$f7.data.dflt_site_name;
        var site_ = this.$f7.data.dflt_site;

        // comment enregistrer le site ! qui vient des lignes ?
        const newOrder = {
          num_order: "",
          code_client: this.numcompte,
          code_operateur : this.numoperateur,
          site: site_,
          name_site: sitelbl_,
          datecreat: ndate,
          timecreat: ntime,
          lignes: tableLigne,
          exporter: "N"
        };
        debugger;
        var result = await this.connexion.addOrder( newOrder);

        //results will contains no of rows added.
        console.log("Result: " + JSON.stringify(result));
        var count = result.length;
        console.log( "Count :" + count);

        //modif num_order
        debugger;
        var my_id = result[0].id;
        var new_num_order =  "CDE" + ("0000" + my_id).slice(-4) + "_" + timestamp;

        result[0].num_order = new_num_order;
        var resultupdt = await this.connexion.updateOrdersById( my_id , result[0]);
      },


     openUpdateOrder: async function (){
        debugger;
        const app = this.$f7;
        var tableLigne = app.data.tableLigne;
        const d = new Date();
        const ndate = ("0" + d.getDate()).slice(-2) + "-" + ("0"+(d.getMonth()+1)).slice(-2) + "-" + d.getFullYear();
        const ntime = ("0" + d.getHours()).slice(-2) + ":" + ("0" + d.getMinutes()).slice(-2);
        var my_id = parseInt ( app.data.currentOrder);
        var timestamp= d.getFullYear()+d.getMonth()+d.getDate()+d.getHours()+d.getMinutes()+d.getSeconds() ;
        var new_num_order =  "CDE" + ("0000" + my_id).slice(-4) + "_" + timestamp;

        // retrouver le libelle du site
        var sitelbl_ = this.$f7.data.dflt_site_name;
        var site_ = this.$f7.data.dflt_site;

        const newOrder = {
          num_order: new_num_order,
          code_client: this.numcompte,
          code_operateur : this.numoperateur,
          site: site_,
          name_site: sitelbl_,
          datecreat: ndate,
          timecreat: ntime,
          lignes: tableLigne,
          exporter: "N"
        };
        debugger;
        var result = await this.connexion.updateOrdersById( my_id, newOrder);

        //results will contains no of rows deleted.
        console.log("Result: " + JSON.stringify(result));
        var count = result.length;
        console.log( "Count :" + count);       
      },

    },

  };
</script>