<template>
<f7-app :params="f7params" >
  <!-- Right panel with reveal effect-->
  <f7-panel right cover theme-dark>
    <f7-view links-view=".view-main">
      <f7-page>
        <f7-navbar title="Menu"></f7-navbar>
        <f7-block>
          <f7-list>
            <f7-list-item link="/settings/" title="Paramètres" panel-close></f7-list-item>
            <f7-list-item link="#" title="Connexion" panel-close  @click.stop="openIdentification()"></f7-list-item>
            <f7-list-item link="#" title="Version: 1.0.0" panel-close></f7-list-item>
            <f7-list-item link="#" title="Mettre à jour" panel-close  @click.stop="openMAJ()"></f7-list-item>
          </f7-list>      
        </f7-block>
      </f7-page>
    </f7-view>
  </f7-panel>

  <f7-view main url="/" :push-state="true"></f7-view>

  <f7-login-screen  ref='Identification' id="my-login-screen">
    <f7-view>
      <f7-page login-screen>
        <f7-login-screen-title>Connexion</f7-login-screen-title>
        <f7-list form>
          <f7-list-input
            type="text"
            name="username"
            placeholder="Votre Identifiant"
            :value="username"
            @input="username = $event.target.value"
          ></f7-list-input>
          <f7-list-input
            type="password"
            name="password"
            placeholder="Votre Mot de passe"
            :value="password"
            @input="password = $event.target.value"
          ></f7-list-input>
        </f7-list>
        <f7-list>
          <f7-list-button title="Valider" login-screen-close @click="connectLoginData"></f7-list-button>
          <f7-block-footer>
            Ces informations vont vous permettre de configurer l'application avec vos données.<br>Cliquer sur  "Valider" pour fermer cet écran
          </f7-block-footer>
        </f7-list>
      </f7-page>
    </f7-view>
  </f7-login-screen>

  <f7-actions ref="actionsMAJ_Mobistock">
    <f7-actions-group>
        <f7-actions-label bold>Une mise à jour de MOBI-STOCK est disponible.</f7-actions-label>
        <f7-actions-button @click="go_refresh('yes')">Mettre à jour</f7-actions-button>
        <f7-actions-button @click="go_refresh('no')">Plus tard</f7-actions-button>
    </f7-actions-group>
  </f7-actions>

</f7-app>
</template>
<script>

  import { Workbox } from "workbox-window";
  import routes from '../js/routes.js';

  import { initJsStore } from "../service/idb_service";
  import { Global } from "./global";

  export default {
    data() {

      let mylogin =  localStorage.getItem("glb_login");
      let mymdp = localStorage.getItem("glb_mdp");

      return {
        // Framework7 Parameters
        f7params: {
          name: 'MOBI-STOCKS', // App name
          theme: 'md', // Automatic theme detection:  auto ios md aurora
          language: 'FR',
          dialog:{ buttonOk: 'OK', buttonCancel:'ANNULER'},
          touch: {
            materialRipple: false
          },
          view: {
            pushState: true,
           // pushStateSeparator:'',
            history: true,
          },

          // App root data
          data: function () {
            return {
             // site_url : "https://sd4.danem.fr/blq_dev2/mobi-stock/",
              site_url : "https://sd4.danem.fr/blq/mobi-stock/",

             // http://mob.blq.fr/account/clients-informations-generales_multi.php?id_client=C2168507

              // table ligne de Cmde temporaire
              tableLigne: [],
              referencement: [],
              search: [],
              dflt_site:"",
              dflt_site_name:"",
              dflt_poste:"",
              dflt_session:"",

              newOrder : false,
              currentOrder: 0,

              newInvt: false,
              currentInvt: 0,
              Invt_num_type: 0,
              ifInvt: false,

              connexion: null,
              globalMediaStream: null
            };
          },

          // App routes
          routes: routes,

          // Register service worker
          /*
          serviceWorker: {
            path: '/service-worker.js',
          },
          */
        },

        // Login screen data
        username: mylogin,
        password: mymdp,
        connexionRef: null,

        //sw alerte
        notifCounter: 0,
        refreshing: false,
        registration: null,
        updateExists: false,
        wb: null     
      }
    },
    methods: {

      go_refresh (operation) {

        if ( operation == 'no'){
          // fermer l'action bar
          //this.$f7router.back();
          this.$refs.actionsMAJ_Mobistock.close();
          return;
        }
        
        if ( operation == 'yes'){
          debugger;
          // Handle a user tap on the update app button.
          this.updateExists = false;
          const wb = this.wb;

          if (this.registration ){
            this.registration.postMessage({ type: "SKIP_WAITING" });
          }
          else{
            wb.addEventListener("controlling", event => {
             window.location.reload();
            });

            debugger;
            // Send a message telling the service worker to skip waiting.
            // This will trigger the `controlling` event handler above.
            wb.messageSW({ type: "SKIP_WAITING" });
          }
        }
      },

      showRefreshUI() {
        // Display a button inviting the user to refresh/reload the app due
        this.updateExists = true;
        this.$refs.actionsMAJ_Mobistock.open();
      },

      refreshReferencement: async function() {
        this.$f7.data.referencement = await this.connexionRef.getReferencement();
      },

      openIdentification(){
        this.$refs.Identification.open();
      },

      openMAJ() {
        window.location.reload();
      },

      connectLoginData() {
        const app = this.$f7;
        let thecall = this.$f7.data.site_url + 'request_mobistk01.php?filename=IDT&login='+ this.username + '&mdp=' + this.password ;

        app.request.get( thecall, (data) =>  {
                        
            let mydata= JSON.parse(data);
            let retour = mydata.idt;
            
            if ( retour[0].result == 'OK') {
              localStorage.setItem("glb_login", retour[0].login);
              localStorage.setItem("glb_mdp", retour[0].mdp);
              localStorage.setItem("glb_numcompte", retour[0].numcompte);
              app.dialog.alert('Connexion réussie');
            }
            else {
              localStorage.setItem("glb_login", "");
              localStorage.setItem("glb_mdp", "");   
              localStorage.setItem("glb_numcompte", ""); 
              app.dialog.alert('Echec de connexion');
            }
            return;

           }, (err) =>  {
               debugger;
               app.dialog.alert('Erreur: réseau non disponible');
           });       
      },

      alertLoginData() {
        this.$f7.dialog.alert('Username: ' + this.username + '<br>Password: ' + this.password);
      },

      urlB64ToUint8Array ( base64String ) {
        const padding = "=".repeat((4 - (base64String.length % 4)) % 4);
        const base64 = (base64String + padding)
        .replace(/\-/g, "+")
        .replace(/_/g, "/");
        const rawData = atob(base64);
        const outputArray = new Uint8Array(rawData.length);
        for (let i = 0; i < rawData.length; ++i) {
          outputArray[i] = rawData.charCodeAt(i);
        }
        return outputArray;
      },

    },
    created() {
      this.$f7ready((f7) => {
         console.log("Hello created F7");
        // Call F7 APIs here

        if ("serviceWorker" in navigator) {
          window.addEventListener("load", () => {
            this.wb = new Workbox("/service-worker.js",{scope: '/'}); /* ./  */ 
            const wb = this.wb;

            // Fires when the registered service worker has installed but is waiting to activate.            
            wb.addEventListener("waiting", event => {
              console.log("**** DANEM: WAITING");
              this.showRefreshUI(); 
            });
            
            console.log("**** DANEM: REGISTER");
            wb.register();
            
            navigator.serviceWorker.ready.then((registration) => {

              console.log("**** DANEM: REGISTER Ok ");

              registration.addEventListener('updatefound', () => { 
                console.log("**** DANEM: UPDATEFOUND");

                // An updated service worker has appeared in reg.installing!
                this.registration = registration.installing;

                // Refresh all open app tabs when a new service worker is installed.
                navigator.serviceWorker.addEventListener("controllerchange", () => {
                  if (this.refreshing) return;
                  this.refreshing = true;
                  window.location.reload();
                });

                this.showRefreshUI(); 
              });

              setInterval(() => {
                registration.update();
              }, 1000 * 3 * 60); // e.g. 3 minutes checks: 1000 * 3 * 60  hourly checks:1000 * 60 * 60
           
              /*
              // public vapid key generate with web-push command line 
              const publicKey = 'BBAirg_W5c0Gc8_qTiuuZdoqWbTR2lISLdpp-NItWmjj0Txk-GsvWYBrdcxpvayFq6zvHipgxYxwut6t1FBZ4pM';
             
              //registration.pushManager.subscribe({userVisibleOnly: true});
              registration.pushManager.getSubscription().then(subscription => {
                if (subscription) {
                  debugger;
                  console.log('***** subscription', subscription);
                  // no more keys proprety directly visible on the subscription objet. So you have to use getKey()
                  const endpoint = subscription.endpoint;
                  const keyArrayBuffer = subscription.getKey('p256dh');
                  const authArrayBuffer = subscription.getKey('auth');
                  const p256dh = btoa(String.fromCharCode.apply(null, new Uint8Array(keyArrayBuffer)));
                  const auth = btoa(String.fromCharCode.apply(null, new Uint8Array(authArrayBuffer)));

                  console.log('p256dh key', keyArrayBuffer, p256dh);
                  console.log('auth key', authArrayBuffer, auth);

                  localStorage.setItem("mobistock_p256dh", p256dh);
                  localStorage.setItem("mobistock_auth", auth);
                  localStorage.setItem("mobistock_endpoint", endpoint);
                } else {
                  // ask for a subscription 
                  const convertedKey = this.urlB64ToUint8Array(publicKey);
                  return registration.pushManager.subscribe({
                    userVisibleOnly: true,
                    applicationServerKey: convertedKey
                  })
                  .then(newSubscription => {
                    // TODO post to a subscription DB
                    console.log('****** newSubscription', newSubscription);

                    // no more keys proprety directly visible on the subscription objet. So you have to use getKey()
                    const endpoint = newSubscription.endpoint;
                    const keyArrayBuffer = newSubscription.getKey('p256dh');
                    const authArrayBuffer = newSubscription.getKey('auth');
                    const p256dh = btoa(String.fromCharCode.apply(null, new Uint8Array(keyArrayBuffer)));
                    const auth = btoa(String.fromCharCode.apply(null, new Uint8Array(authArrayBuffer)));
                    console.log('p256dh key:', keyArrayBuffer, p256dh);
                    console.log('auth key:', authArrayBuffer, auth);
        
                    //ou dans localstorage  pour envouyer plustard avec id du user !!
                    localStorage.setItem("mobistock_p256dh", p256dh);
                    localStorage.setItem("mobistock_auth", auth);
                    localStorage.setItem("mobistock_endpoint", endpoint);
                  })
                }
              });  // end of suscription
              */
              });  //end of register

          });
        }

      });

    },

    mounted() {
      this.$f7ready((f7) => {
        debugger;
         console.log("Hello mounted F7");
        // Call F7 APIs here
        try {
          const isDbCreated = initJsStore();
          if (isDbCreated) {
            console.log("db created");
            // prefill database
          } else {

            console.log("db opened");
          }
        } catch (ex) {
          console.error(ex);
          alert(ex.message);
          Global.isIndexedDbSupported = false;
        }

        debugger;
        /***    repositionner toutes les variables pour refresh  ***/
        let local_referencement = localStorage.getItem("referencement");
        if ( local_referencement != null ) {  
          this.$f7.data.referencement = JSON.parse(local_referencement);
        }
        else
          this.$f7.data.referencement=[];


        let local_search = localStorage.getItem("search");
        if ( local_search != null ) {  
          this.$f7.data.local_search = JSON.parse(local_search);
        }
        else
          this.$f7.data.local_search=[];

        /*
        //myScreenOrientation.unlock();
        */
/*
        try {
          //document.body.requestFullscreen();

          var myScreenOrientation = window.screen.orientation;
          myScreenOrientation.lock("portrait");
        } catch (err) {
          console.error("DANEM :"+ err);
        }
*/
      });
    }
  }
</script>
