<template>
  <f7-page name="cmde_liste">
    <!-- <f7-navbar title="MOBI-STOCKS"  subtitle="Liste commandes non transmises" back-link-force back-link="Back"  back-link-url="/"> -->
    <f7-navbar>
      <f7-nav-left>
       <f7-link icon-ios="f7:arrow_left" icon-aurora="f7:arrow_left" icon-md="material:arrow_back" @click.stop="$f7router.navigate('/', {force: true, clearPreviousHistory: true})" ></f7-link>
      </f7-nav-left> 

      <f7-nav-title title="MOBI-STOCKS" subtitle="Liste commandes non transmises" ></f7-nav-title> 

      <f7-nav-right>
       <f7-link icon-ios="f7:close" icon-aurora="f7:close" icon-md="material:close" @click.stop="$f7router.navigate('/', {force: true, clearPreviousHistory: true})" ></f7-link>
      </f7-nav-right>  

    </f7-navbar>
    <f7-block-title>&nbsp;</f7-block-title>
     <!--  text="Commander" text="Transmettre"  -->
    <f7-fab style="transition-duration: 50ms;" position="right-top" slot="fixed" @click="openNewOrder">
      <f7-icon ios="f7:add_shopping_cart" aurora="f7:add_shopping_cart" md="material:add_shopping_cart"></f7-icon>
    </f7-fab>

    <f7-fab position="center-bottom" slot="fixed" color="green" @click="sendOrder">
      <f7-icon ios="f7:send" aurora="f7:send" md="material:send"></f7-icon>
    </f7-fab>

    <f7-block > </f7-block>
    <f7-block class="searchbar-hide-on-search">
      <p>Pour une nouvelle commande, cliquez sur le Bouton <strong>Bleu</strong>.</br></br>Pour transmettre la ou les commandes, sélectionnez les, puis cliquez sur le Bouton <strong>Vert</strong> d'envoi.</p>
    </f7-block>

<!-- <f7-list media-list> -->
<f7-list>
  <f7-list-item
    v-for="(link, index) in orders"
    :key="index"
    :value="link.id"
    @click.stop="openOrder( link.id, link.num_order )"
  >
    &nbsp;&nbsp;&nbsp;<f7-link color="red"  icon-f7="trash"  @click.stop="openSupprimer( index, link.id, link.num_order )" ></f7-link>
    <div slot="title" style="white-space: normal"><label class="checkbox"  @click.stop="stopTheEvent"><input class="icon-checkbox" type="checkbox" :value="link.id" @change="onSelectionChange"><i class="icon-checkbox"></i></label>&nbsp;&nbsp;&nbsp;{{link.num_order}}</div>
    <div slot="title" style="white-space: normal">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{{link.name_site}}</div>
    <div slot="after" style="white-space: normal">{{link.datecreat}}</br><center>{{link.timecreat}}</center></div>
  </f7-list-item>
</f7-list>

  </f7-page>
</template>

<script>
import { OrderService } from "../service/Order_service";
import { debuglog } from 'util';

export default {
  data: function () {
    return {
      connexion: null,     // centraliser la connexion !!!
      orders: [],
      selection: [],
    };
  },

  mounted () {
    this.connexion = new OrderService();
    this.refreshOrder();
    //let  db_name = "MOBI-STOCKS_DB";
    //this.connexion.getDbVersion(db_name).then(function(version) { console.log(version) })
  },  

  methods: {

    stopTheEvent ( e ){

    },

    openNewOrder() {
        const app = this.$f7;
        this.$f7.data.newOrder = true;
        this.$f7.data.currentOrder = 0;
        this.$f7.data.ifInvt =  false;

        let  ReferencementOK = false;
        if (Array.isArray(app.data.referencement) && app.data.referencement.length) {
          // array exists and is not empty
          ReferencementOK = true;
        }

        if (ReferencementOK == false){
            app.dialog.alert('Vous n\'avez pas de référencement, faite une synchronisation !');
            return;
        }

        // Effacer les lignes de commande précédentes DYNAMIQUE
        this.$f7.data.tableLigne = [];

        localStorage.setItem('tableLigne', JSON.stringify(this.$f7.data.tableLigne));

        localStorage.setItem('newOrder', JSON.stringify(this.$f7.data.newOrder));
        localStorage.setItem('currentOrder', JSON.stringify(this.$f7.data.currentOrder));
        localStorage.setItem('ifInvt', JSON.stringify(this.$f7.data.ifInvt));

        // Positionner les valeurs par défaut DYNAMIQUE
        localStorage.setItem('dflt_site', "");
        localStorage.setItem('dflt_site_name', "");
        localStorage.setItem('dflt_poste', "");

        if ( 1 )
          app.views.main.router.navigate( '/product_liste/');
        else
          app.views.main.router.navigate( '/product_find/');      

    },

    onSelectionChange: function (e) {
      var value = e.target.value;
      e.stopPropagation();

      var selection = this.selection;
      if (e.target.checked) {
        selection.push(value);
      } else {
        selection.splice(selection.indexOf(value), 1);
      }
    },

    refreshOrder: async function() {
        var result = await this.connexion.getOrder();
        this.orders = result;
    }, 

    sendOrder: async function() {  
 
      const app = this.$f7;
      var orders = this.orders;
      var tosend = [];
      var selection = this.selection;

      if (selection.length == 0 ){
          app.dialog.alert('Vous avez sélectionné aucune commande !');
          return;
      }

      // Ici faire boucle de remplissage
      for (let j = 0; j < selection.length; j += 1) {
          let numcde = selection[j]; 
          for (let i = 0; i < orders.length; i += 1) {
            if ( orders[i].id ==  numcde ){
   		        tosend.push(orders[i]);
		          break;
	          }
          } 
      }
 
      app.preloader.show();
      let st_login=  localStorage.getItem("glb_login");
      let st_mdp=  localStorage.getItem("glb_mdp");
      let thecall = this.$f7.data.site_url + "send_mobistk01.php";
      app.request.post(thecall, { login: st_login, mdp: st_mdp ,trame: tosend }  , (data) =>  {
            console.log(data);
            app.preloader.hide();

            let mydata= JSON.parse(data);
            let result = mydata[0].result;

            if (result == 'KO') {
              let thetext = mydata[0].erreur;
              app.dialog.alert('Erreur de connexion ! <br>'+ thetext );
            }
            else {
                
              app.dialog.alert('Transfert avec succès !',  () => {
                  
                // effacer les commandes puis refresh
                for (let i = 0; i < tosend.length; i += 1) {
                  let id = tosend[i].id;
                  var retour = this.connexion.removeOrder(id);
                } 

                //app.views.main.router.navigate( '/cmde_liste/');
                app.views.main.router.refreshPage(); 
              });
            }

            return;
           }, (err) =>  {
               app.preloader.hide();
               app.dialog.alert('Erreur: réseau non disponible');
           });  
    },

    openSupprimer: function ( indexToDelete, id , myOrderName) {
          var app = this.$f7;
          var tableLigne = this.orders;
          //var mynum =  "CDE" + ("0000" + id).slice(-4);
          var mytext = "Confirmez-vous la suppression de la commande " + myOrderName + " ?";
          app.dialog.confirm(mytext,  () => {
            var result = this.connexion.removeOrder(id);
            // supprimer de la liste
            tableLigne.splice(indexToDelete, 1);
          });
    },

    openAnnulation (){
        const app = this.$f7;
        app.views.main.router.navigate( '/');
        // $f7router.navigate 
    },

    openOrder( myOrder, myOrderName ){
      var mytext = "Confirmez-vous vouloir modifier la commande " + myOrderName + " ?";

      const app = this.$f7;
      this.$f7.data.newOrder = false;
      this.$f7.data.currentOrder = 0;
      this.$f7.data.ifInvt = false;
      
      this.$f7.data.tableLigne = [];
      var theOrders = this.orders;

      app.dialog.confirm(mytext,  () => {
        /* trouver et prendre */
        for (let i = 0; i < theOrders.length; i += 1) {
          var theOrder = theOrders[i];
          var toto = theOrder['id'];

          if (toto == myOrder ) {
            /* reprend toutes les lignes de la commande */
            this.$f7.data.tableLigne = theOrder['lignes'].slice();

            this.$f7.data.dflt_site = theOrder['site'];
            this.$f7.data.dflt_site_name =  theOrder['name_site'];
            this.$f7.data.dflt_poste ="";
            this.$f7.data.currentOrder = myOrder;

            localStorage.setItem('newOrder', JSON.stringify(this.$f7.data.newOrder));
            localStorage.setItem('currentOrder', JSON.stringify(this.$f7.data.currentOrder));
            localStorage.setItem('ifInvt', JSON.stringify(this.$f7.data.ifInvt));

            localStorage.setItem('tableLigne', JSON.stringify(this.$f7.data.tableLigne));
            localStorage.setItem('dflt_site', this.$f7.data.dflt_site);
            localStorage.setItem('dflt_site_name',  this.$f7.data.dflt_site_name);
            localStorage.setItem('dflt_poste',  this.$f7.data.dflt_poste);

            break;
          }
        }

        /* ouvrir */
        app.views.main.router.navigate( '/cmde_ligne/');

      });

    },
    
  }
};
</script>
