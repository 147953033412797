<template>
  <f7-page name="product">
    <f7-navbar title="Fiche Article Détaillée" back-link="Back">
      <f7-nav-right>
        <f7-link icon-ios="f7:close" icon-aurora="f7:close" icon-md="material:close" back ></f7-link>
      </f7-nav-right>  
    </f7-navbar>
    
    <f7-block>
      <f7-row no-gap>
        <f7-col width="30"><strong>Référence Interne:</strong></f7-col>
        <f7-col width="70">{{product.referenceinterne }}</f7-col>
      </f7-row>
      <f7-row no-gap>
        <f7-col width="30"><strong>Désignation Interne:</strong></f7-col>
        <f7-col width="70">{{product.designationinterne}}</f7-col>
      </f7-row>
      <f7-row no-gap>
        <f7-col width="30"><strong>Référence Client:</strong></f7-col>
        <f7-col width="70">{{product.referenceclient}}</f7-col>
      </f7-row>
      <f7-row no-gap>
        <f7-col width="30"><strong>Désignation Client:</strong></f7-col>
        <f7-col width="70">{{product.designationclient}}</f7-col>
      </f7-row>
      <f7-row no-gap>
        <f7-col width="30"><strong>Code du fournisseur:</strong></f7-col>
        <f7-col width="70">{{product.codefournisseur}}</f7-col>
      </f7-row>
      <f7-row no-gap>
        <f7-col width="30"><strong>Désignation du fournisseur:</strong></f7-col>
        <f7-col width="70">{{product.desfournisseur}}</f7-col>
      </f7-row>
      <f7-row no-gap>
        <f7-col width="30"><strong>EAN du Fournisseur:</strong></f7-col>
        <f7-col width="70">{{product.eanfournisseur}}</f7-col>
      </f7-row>
      <f7-row no-gap>
        <f7-col width="30"><strong>Confidentialite:</strong></f7-col>
        <f7-col width="70">{{product.confidentialite}}</f7-col>
      </f7-row>
    </f7-block>

<!--  
        style="background-image:url(`${product.img}`)"
   style="background-image:url(https://sd4.danem.fr/blq_dev2/imgs/photos_articles/7708511_1.jpg)"
    :style="`background-image:url(${product.img})`"

      <f7-card-header
        class="no-border"
        valign="bottom"
        :style="`background-image:url(${product.img})`"
      ></f7-card-header>
      width="100%"

      <f7-card-content :padding="false">
        <img :src="`${product.img}`" />
      </f7-card-content>

-->
    <f7-card class="mobi-card-header-pic">
      <f7-card-header
        class="no-border"
        valign="bottom"
        :style="`background-image:url(${product.img})`"
      ></f7-card-header>
    </f7-card>

  </f7-page>
</template>
<script>
  import { ReferencementService } from "../service/referencement_service";

  export default {
    data: function () {
      return {
        product: { referenceinterne: "", designationinterne: "", ean:"", referenceclient: "", designationclient: "", codefournisseur: "", desfournisseur: "", eanfournisseur: "", confidentialite: "", img:""},
      };
    },
    methods: {
       findReferencement: async function( ref_interne) {
        //var result = await new ReferencementService().getReferencementByRefInterne( ref_interne);
        
        const service = new ReferencementService();    /* connexion */
        var result = await service.getReferencementByRefInterne( ref_interne);

        //results will contains no of rows deleted.
        console.log("Result: " + JSON.stringify(result));
        var count = result.length;
        console.log( "Count :" + count);
        if ( count > 0) {
          this.product = result[0];
        }
      }     
    },
    mounted: function() {
      var productId = this.$f7route.params.id;
      this.findReferencement( productId );
    },  
  };
</script>

<style>
.mobi-card-header-pic .card-header {
    height: 50vw;
    background-size: contain;
    background-position: center;
    background-repeat:no-repeat;
    color: #fff;
}
</style>


