<template>
  <f7-page name="product_find">
    <f7-navbar title="MOBI-STOCKS" subtitle="Recherche Manuelle" back-link="Back">
      <f7-nav-right>
        <f7-link icon-ios="f7:close" icon-aurora="f7:close" icon-md="material:close" back ></f7-link>
      </f7-nav-right>  
    </f7-navbar>
    <f7-fab position="right-top" slot="fixed" text="Chercher" @click="find_ref">
     <f7-icon ios="f7:search" aurora="f7:search" md="material:search"></f7-icon>
    </f7-fab>
    <f7-block >
      &nbsp;
    </f7-block>
    <f7-list no-hairlines-md>
      <f7-list-input
        label="Code Article"
        type="text"
        placeholder="Votre recherche"
        :value="edit_ref"
        @input="edit_ref = $event.target.value"
        clear-button
      ></f7-list-input>
    </f7-list>
    <!--
    <f7-block >
          <f7-button large fill raised   @click="find_ref" >Chercher</f7-button>
    </f7-block>
    -->
    <f7-popup class="popup-detail" swipe-to-close>
      <f7-page>
        <f7-navbar title="Fiche Article">
          <f7-nav-right>
            <f7-link popup-close><f7-icon ios="f7:close" aurora="f7:close" md="material:close"></f7-icon></f7-link>
          </f7-nav-right>
        </f7-navbar>

      <f7-block>
      <f7-row no-gap>
        <f7-col width="30">Référence:</f7-col>
        <f7-col width="70">{{product.referenceinterne }}</f7-col>
      </f7-row>
      <f7-row no-gap>
        <f7-col width="30">Désignation:</f7-col>
        <f7-col width="70">{{product.designationinterne}}</f7-col>
      </f7-row>
      <f7-row no-gap>
        <f7-col width="30"><br></f7-col>
        <f7-col width="70"></f7-col>
      </f7-row>
      <f7-row no-gap>
        <f7-col width="30">Site:</f7-col>
        <f7-col width="70">
          <f7-input type="text" :value="edit_site" @input="edit_site = $event.target.value" clear-button></f7-input>
        </f7-col>
      </f7-row>
      <f7-row no-gap>
        <f7-col width="30">Poste:</f7-col>
        <f7-col width="70">
          <f7-input type="text" :value="edit_poste" @input="edit_poste = $event.target.value" clear-button></f7-input>
        </f7-col>
      </f7-row>
      <f7-row no-gap>
        <f7-col width="30"><br></f7-col>
        <f7-col width="70"></f7-col>
      </f7-row>
      <f7-row no-gap>
        <f7-col width="30">Conditionnement:</f7-col>
        <f7-col width="70">
          <f7-input type="select" :value="edit_condition" @input="edit_condition = $event.target.value">
            <option v-for="cdt in cdts" :key="cdt.code" :value="cdt.code">{{cdt.libelle}}</option>   
          </f7-input>
        </f7-col>
      </f7-row>
      <f7-row no-gap>
        <f7-col width="30"><br></f7-col>
        <f7-col width="70"></f7-col>
      </f7-row>
      <f7-row no-gap>
        <f7-col width="30">Quantité:</f7-col>
        <f7-col width="70">
          <f7-input type="number" :value="edit_quantite" @input="edit_quantite = $event.target.value" clear-button>
          </f7-input>
        </f7-col>
      </f7-row>
      </f7-block>     

    <f7-block >
          <f7-button large fill raised  @click="add_ref" >Ajouter à la liste</f7-button>
    </f7-block>   
  
      </f7-page>
    </f7-popup>

  </f7-page>
</template>

<script>
  import { ReferencementService } from "../service/referencement_service";

  export default {
    data: function () {
      return {
        connexion: null,     // centraliser la connexion !!!
        edit_ref:"",
        edit_quantite: 1,
        edit_site: this.$f7.data.dflt_site,
        edit_poste: this.$f7.data.dflt_poste,
        edit_condition: "",
        //cdts: [{code: "PI-1-PI", libelle: "Pièce", qte: "1"}, {libelle: "Boite de 10 Pièces", qte: "10"},{code: "C-60-PI", libelle: "Carton de 60 Pièces", qte: "60"},{code: "P-1,920-PI", libelle: "Palette de 1,920 Pièces", qte: "1,920"}],
        cdts: [{code: "PI-1-PI", libelle: "Pièce", qte: "1"},{code: "B-2-PI", libelle: "Boite de 2 Pièces", qte: "2"},{code: "B-10-PI", libelle: "Boite de 10 Pièces", qte: "10"},{code: "P-30-PI", libelle: "Palette de 30 Pièces", qte: "30"},{code: "C-60-PI", libelle: "Carton de 60 Pièces", qte: "60"},{code: "P-1,920-PI", libelle: "Palette de 1,920 Pièces", qte: "1,920"}],
        product: { referenceinterne: "", designationinterne: "", ean:"", referenceclient: "", designationclient: "", codefournisseur: "", desfournisseur: "", eanfournisseur: "", confidentialite: "", dflt_cdt:"", cdts:""},
      };
    },
    methods: {
        find_ref() {
          debugger
          const app = this.$f7;
          var items = this.$f7.data.tableLigne;
          var myref = this.edit_ref ; 
          let ifpresent = false;

          // d'abord cherche en locale
          if (  myref.trim() !== '' ) {
            for (let i = 0; i < items.length; i += 1) {
              if (items[i].ref.toLowerCase().indexOf(myref.toLowerCase()) >= 0 ) {
                ifpresent = true;
                break;
              }
            }

            //puis  dans la Base
            if( ifpresent ) {
              app.dialog.alert('Référence déjà saisie ! ');
            }
            else
              this.findReferencement( this.edit_ref );
           }

        }, 
        add_ref () {
          debugger;
          const app = this.$f7;

          // ajouter au tableau Globale
          let tableLigne = app.data.tableLigne;
          //tableLigne.push( {
          tableLigne.unshift( {
            ref: this.product.referenceinterne,
            des: this.product.designationinterne,
            site: this.edit_site,
            poste: this.edit_poste,
            cdt: this.edit_condition,
            qte: this.edit_quantite
          } );
        
          // fermer la popup
          app.popup.close(".popup-detail");
        },
       findReferencement: async function( ref_interne) {
        const app = this.$f7;
        var result = await this.connexion .getReferencementByRefInterne( ref_interne);
        //results will contains no of rows deleted.
        console.log("Result: " + JSON.stringify(result));
        var count = result.length;
        console.log( "Count :" + count);
        if ( count > 0) {
          this.product = result[0];
          this.edit_condition= this.product.dflt_cdt;
          //this.edit_condition= "B-2-PI";
          this.cdts = this.product.cdts;
          debugger
          app.popup.open(".popup-detail");
        }else {

          app.dialog.alert('Référence non trouvée ! ');
        }
      },
    },
    mounted: function() {
      this.connexion = new ReferencementService();
    },  
  };
</script>
